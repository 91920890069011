import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c(VCard,[_c(VCardText,[_c(VCardActions,[_c('div',{staticClass:"text-capitalize headline"},[_vm._v(" "+_vm._s(_vm._f("trans")(_vm.resource))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm._f("trans")("crud.save")))])],1),_c('items-reorder',{attrs:{"title":_vm.title,"description":_vm.description},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),(_vm.items.length > 10)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm._f("trans")("crud.save")))])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }