<template>
	<v-container>
		<v-progress-linear indeterminate v-if="loading"></v-progress-linear>
		<v-card>
			<v-card-text>
				<v-card-actions>
					<div class="text-capitalize headline">
						{{ resource | trans }}
					</div>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="save">{{ "crud.save" | trans }}</v-btn>
				</v-card-actions>
				<items-reorder v-model="items" :title="title" :description="description"></items-reorder>
				<v-card-actions v-if="items.length > 10">
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="save">{{ "crud.save" | trans }}</v-btn>
				</v-card-actions>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
export default {
	async mounted() {
		this.loading = true;
		var items = (await this.api.get(`${this.resource}/tree`)).data;
		this.items = [];
		for (const key in items) {
			var item = items[key];
			if (!item.id) continue;
			this.items.push(item);
		}
		console.log(this.$route.query);
		if (this.$route.query.title) {
			this.title = this.$route.query.title;
		}
		if (this.$route.query.description) {
			this.description = this.$route.query.description;
		}
		this.loading = false;
	},
	methods: {
		save() {
			this.toSave = [];
			this.setParent(this.items);
			console.log(this.toSave);
			if (this.toSave.length > 0) {
				this.api
					.post(`${this.resource}/tree`, {
						items: this.toSave,
					})
					.then((resp) => {
						this.api.successful(resp);
					})
					.catch((err) => {
						this.api.handleError(err);
					});
			}
		},
		setParent(items, parent_id = null) {
			items.forEach((e) => {
				if (parent_id != e.parent_id) {
					e.parent_id = parent_id;
					this.toSave.push({
						id: e.id,
						parent_id: parent_id,
					});
				}
				if (e.children.length > 0) this.setParent(e.children, e.id);
			});
		},
	},
	data() {
		return {
			loading: false,
			toSave: [],
			items: [],
			title: "name",
			description: "codigo",
		};
	},
	props: {
		resource: {
			type: String,
			required: true,
		},
	},
};
</script>
