<template>
	<v-container fluid>
		<v-card>
			<v-card-text>
				<crud
					id="clientes"
					resource="clientes"
					create-permission="staff"
					edit-permission="staff"
					delete-permission="staff"
					revisions="staff"
					default-order="id"
					@add-item="setItem"
					@edit-item="setItem"
					@cancel-edition="setItem"
					hide-delete
					:before-save="checkDates"
				></crud>
			</v-card-text>
		</v-card>
	</v-container>
</template>
<script>
import moment from "moment";
export default {
	data() {
		return {
			item: null,
		};
	},
	methods: {
		setItem(item) {
			this.item = item;
		},
		checkDates(item) {
			if (item.fecha_inicio_facturacion)
				item.fecha_inicio_facturacion = moment(item.fecha_inicio_facturacion).format("YYYY-MM-DD");
			if (item.fecha_fin_facturacion)
				item.fecha_fin_facturacion = moment(item.fecha_fin_facturacion).format("YYYY-MM-DD");
			//   Contingencia
			if (item.contingencia_fecha_inicio_facturacion)
				item.contingencia_fecha_inicio_facturacion = moment(item.contingencia_fecha_inicio_facturacion).format(
					"YYYY-MM-DD"
				);
			if (item.contingencia_fecha_fin_facturacion)
				item.contingencia_fecha_fin_facturacion = moment(item.contingencia_fecha_fin_facturacion).format("YYYY-MM-DD");
		},
		autoDetectDocument(item) {
			if (item.tipo_contribuyente == "Cliente del Exterior") {
				return (item.tipo_documento = "Documento de identificacion extranjero");
			}
			if (
				(item.tipo_contribuyente == "Persona Juridica" || item.tipo_contribuyente == "Gran Contribuyente") &&
				(item.tipo_documento == "Documento de identificacion extranjero" ||
					item.tipo_documento == "Cédula de ciudadania")
			) {
				return (item.tipo_documento = "NIT");
			}
			if (item.tipo_contribuyente == "Persona Natural") {
				return (item.tipo_documento = "Cédula de ciudadania");
			}
		},
	},
	watch: {
		"item.tipo_contribuyente": {
			handler() {
				if (this.item && this.item.tipo_contribuyente) {
					this.autoDetectDocument(this.item);
				}
			},
		},
	},
};
</script>
